import React from 'react';

import './App.css';

import myresume from './Documents/resume.pdf';

function App() {
	return (
		<div className="container-fluid h-100">
			<div className="row h-100">
				<div className="col-12 col-md-8 mx-auto">
					<div className="d-flex justify-content-center align-items-center h-100 my-auto">
						<div className='my-auto'>
							<h1 className="py-3">hi, I'm Timothy Mwiti</h1>
							<h1 className="py-3">view my  <span className="text-primary">
								<a href={myresume} target='_blank' rel='noreferrer'>resume</a></span>
							</h1>
							<h1 className="py-3">tweet me <span className="text-primary">
								<a href="https://www.twitter.com/timkanake" target='_blank' rel='noreferrer'>@timkanake</a></span>
							</h1>
							<h1 className="py-3"><span className="text-primary">
								<a href="mailto:mwititimothyk@gmail.com">send me an email</a>
								</span>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		);
}

export default App;